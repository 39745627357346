<template>
  <section>
      <FooterPaymentPolicy/>
  </section>
</template>

<script>
import FooterPaymentPolicy from '@/components/Footer/FooterPaymentPolicy.vue';
export default {
    name: "PaymentPolicy",
    components: {
      FooterPaymentPolicy
    },
    mounted() {
      document.title = `${process.env.VUE_APP_TITLE} - Payment Policy`
      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-CRY3HG283C')
      document.head.appendChild(recaptchaScript)
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-CRY3HG283C');
    }
}
</script>

<style>

</style>